/**
 * Layout overrides to dashkit/theme.min.css breakpoints
 */

 @media (min-width: 992px) {
    .col-lg-10 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  @media (min-width: 1200px) {
    .col-xl-8 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  @media (min-width: 1400px) {
    .col-xl-8 {
      flex: 0 0 100%;
      width: 100%;
      max-width: 1200px;
    }
  }
  