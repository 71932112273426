/*
 *
 * Tailwind whitespace
 *
 */

/* Normal Margin */

.m-0\.5 {
  margin: 2px;
}

.m-1 {
  margin: 4px;
}

.m-1\.5 {
  margin: 6px;
}

.m-2 {
  margin: 8px;
}

.m-2\.5 {
  margin: 10px;
}

.m-3 {
  margin: 12px;
}

.m-3\.5 {
  margin: 14px;
}

.m-4 {
  margin: 16px;
}

.m-5 {
  margin: 20px;
}

.m-6 {
  margin: 24px;
}

.m-7 {
  margin: 28px;
}

.m-8 {
  margin: 32px;
}

.m-9 {
  margin: 36px;
}

.m-10 {
  margin: 40px;
}

.m-11 {
  margin: 44px;
}

.m-12 {
  margin: 48px;
}

.m-14 {
  margin: 56px;
}

.m-16 {
  margin: 64px;
}

.m-20 {
  margin: 80px;
}

.m-24 {
  margin: 96px;
}

.m-28 {
  margin: 112px;
}

.m-32 {
  margin: 128px;
}

.m-36 {
  margin: 144px;
}

.m-40 {
  margin: 160px;
}

.m-44 {
  margin: 176px;
}

.m-48 {
  margin: 192px;
}

.m-52 {
  margin: 208px;
}

.m-56 {
  margin: 224px;
}

.m-60 {
  margin: 240px;
}

.m-64 {
  margin: 256px;
}

.m-72 {
  margin: 288px;
}

.m-80 {
  margin: 320px;
}

.-m-0\.5 {
  margin: -2px;
}

.-m-1 {
  margin: -4px;
}

.-m-1\.5 {
  margin: -6px;
}

.-m-2 {
  margin: -8px;
}

.-m-2\.5 {
  margin: -10px;
}

.-m-3 {
  margin: -12px;
}

.-m-3\.5 {
  margin: -14px;
}

.-m-4 {
  margin: -16px;
}

.-m-5 {
  margin: -20px;
}

.-m-6 {
  margin: -24px;
}

.-m-7 {
  margin: -28px;
}

.-m-8 {
  margin: -32px;
}

.-m-9 {
  margin: -36px;
}

.-m-10 {
  margin: -40px;
}

.-m-11 {
  margin: -44px;
}

.-m-12 {
  margin: -48px;
}

.-m-14 {
  margin: -56px;
}

.-m-16 {
  margin: -64px;
}

.-m-20 {
  margin: -80px;
}

.-m-24 {
  margin: -96px;
}

.-m-28 {
  margin: -112px;
}

.-m-32 {
  margin: -128px;
}

.-m-36 {
  margin: -144px;
}

.-m-40 {
  margin: -160px;
}

.-m-44 {
  margin: -176px;
}

.-m-48 {
  margin: -192px;
}

.-m-52 {
  margin: -208px;
}

.-m-56 {
  margin: -224px;
}

.-m-60 {
  margin: -240px;
}

.-m-64 {
  margin: -256px;
}

.-m-72 {
  margin: -288px;
}

.-m-80 {
  margin: -320px;
}

/* Margin Left (ml) */
.ml-0\.5 {
  margin-left: 2px;
}

.ml-1 {
  margin-left: 4px;
}

.ml-1\.5 {
  margin-left: 6px;
}

.ml-2 {
  margin-left: 8px;
}

.ml-2\.5 {
  margin-left: 10px;
}

.ml-3 {
  margin-left: 12px;
}

.ml-3\.5 {
  margin-left: 14px;
}

.ml-4 {
  margin-left: 16px;
}

.ml-5 {
  margin-left: 20px;
}

.ml-6 {
  margin-left: 24px;
}

.ml-7 {
  margin-left: 28px;
}

.ml-8 {
  margin-left: 32px;
}

.ml-9 {
  margin-left: 36px;
}

.ml-10 {
  margin-left: 40px;
}

.ml-11 {
  margin-left: 44px;
}

.ml-12 {
  margin-left: 48px;
}

.ml-14 {
  margin-left: 56px;
}

.ml-16 {
  margin-left: 64px;
}

.ml-20 {
  margin-left: 80px;
}

.ml-24 {
  margin-left: 96px;
}

.ml-28 {
  margin-left: 112px;
}

.ml-32 {
  margin-left: 128px;
}

.ml-36 {
  margin-left: 144px;
}

.ml-40 {
  margin-left: 160px;
}

.ml-44 {
  margin-left: 176px;
}

.ml-48 {
  margin-left: 192px;
}

.ml-52 {
  margin-left: 208px;
}

.ml-56 {
  margin-left: 224px;
}

.ml-60 {
  margin-left: 240px;
}

.ml-64 {
  margin-left: 256px;
}

.ml-72 {
  margin-left: 288px;
}

.ml-80 {
  margin-left: 320px;
}

/* Negative Margin Left (-ml) */
.-ml-0\.5 {
  margin-left: -2px;
}

.-ml-1 {
  margin-left: -4px;
}

.-ml-1\.5 {
  margin-left: -6px;
}

.-ml-2 {
  margin-left: -8px;
}

.-ml-2\.5 {
  margin-left: -10px;
}

.-ml-3 {
  margin-left: -12px;
}

.-ml-3\.5 {
  margin-left: -14px;
}

.-ml-4 {
  margin-left: -16px;
}

.-ml-5 {
  margin-left: -20px;
}

.-ml-6 {
  margin-left: -24px;
}

.-ml-7 {
  margin-left: -28px;
}

.-ml-8 {
  margin-left: -32px;
}

.-ml-9 {
  margin-left: -36px;
}

.-ml-10 {
  margin-left: -40px;
}

.-ml-11 {
  margin-left: -44px;
}

.-ml-12 {
  margin-left: -48px;
}

.-ml-14 {
  margin-left: -56px;
}

.-ml-16 {
  margin-left: -64px;
}

.-ml-20 {
  margin-left: -80px;
}

.-ml-24 {
  margin-left: -96px;
}

.-ml-28 {
  margin-left: -112px;
}

.-ml-32 {
  margin-left: -128px;
}

.-ml-36 {
  margin-left: -144px;
}

.-ml-40 {
  margin-left: -160px;
}

.-ml-44 {
  margin-left: -176px;
}

.-ml-48 {
  margin-left: -192px;
}

.-ml-52 {
  margin-left: -208px;
}

.-ml-56 {
  margin-left: -224px;
}

.-ml-60 {
  margin-left: -240px;
}

.-ml-64 {
  margin-left: -256px;
}

.-ml-72 {
  margin-left: -288px;
}

.-ml-80 {
  margin-left: -320px;
}

/* Margin Right (mr) */
.mr-0\.5 {
  margin-right: 2px;
}

.mr-1 {
  margin-right: 4px;
}

.mr-1\.5 {
  margin-right: 6px;
}

.mr-2 {
  margin-right: 8px;
}

.mr-2\.5 {
  margin-right: 10px;
}

.mr-3 {
  margin-right: 12px;
}

.mr-3\.5 {
  margin-right: 14px;
}

.mr-4 {
  margin-right: 16px;
}

.mr-5 {
  margin-right: 20px;
}

.mr-6 {
  margin-right: 24px;
}

.mr-7 {
  margin-right: 28px;
}

.mr-8 {
  margin-right: 32px;
}

.mr-9 {
  margin-right: 36px;
}

.mr-10 {
  margin-right: 40px;
}

.mr-11 {
  margin-right: 44px;
}

.mr-12 {
  margin-right: 48px;
}

.mr-14 {
  margin-right: 56px;
}

.mr-16 {
  margin-right: 64px;
}

.mr-20 {
  margin-right: 80px;
}

.mr-24 {
  margin-right: 96px;
}

.mr-28 {
  margin-right: 112px;
}

.mr-32 {
  margin-right: 128px;
}

.mr-36 {
  margin-right: 144px;
}

.mr-40 {
  margin-right: 160px;
}

.mr-44 {
  margin-right: 176px;
}

.mr-48 {
  margin-right: 192px;
}

.mr-52 {
  margin-right: 208px;
}

.mr-56 {
  margin-right: 224px;
}

.mr-60 {
  margin-right: 240px;
}

.mr-64 {
  margin-right: 256px;
}

.mr-72 {
  margin-right: 288px;
}

.mr-80 {
  margin-right: 320px;
}

.-mr-0\.5 {
  margin-right: -2px;
}

.-mr-1 {
  margin-right: -4px;
}

.-mr-1\.5 {
  margin-right: -6px;
}

.-mr-2 {
  margin-right: -8px;
}

.-mr-2\.5 {
  margin-right: -10px;
}

.-mr-3 {
  margin-right: -12px;
}

.-mr-3\.5 {
  margin-right: -14px;
}

.-mr-4 {
  margin-right: -16px;
}

.-mr-5 {
  margin-right: -20px;
}

.-mr-6 {
  margin-right: -24px;
}

.-mr-7 {
  margin-right: -28px;
}

.-mr-8 {
  margin-right: -32px;
}

.-mr-9 {
  margin-right: -36px;
}

.-mr-10 {
  margin-right: -40px;
}

.-mr-11 {
  margin-right: -44px;
}

.-mr-12 {
  margin-right: -48px;
}

.-mr-14 {
  margin-right: -56px;
}

.-mr-16 {
  margin-right: -64px;
}

.-mr-20 {
  margin-right: -80px;
}

.-mr-24 {
  margin-right: -96px;
}

.-mr-28 {
  margin-right: -112px;
}

.-mr-32 {
  margin-right: -128px;
}

.-mr-36 {
  margin-right: -144px;
}

.-mr-40 {
  margin-right: -160px;
}

.-mr-44 {
  margin-right: -176px;
}

.-mr-48 {
  margin-right: -192px;
}

.-mr-52 {
  margin-right: -208px;
}

.-mr-56 {
  margin-right: -224px;
}

.-mr-60 {
  margin-right: -240px;
}

.-mr-64 {
  margin-right: -256px;
}

.-mr-72 {
  margin-right: -288px;
}

.-mr-80 {
  margin-right: -320px;
}

/* Margin Top (mt) */
.mt-0\.5 {
  margin-top: 2px;
}

.mt-1 {
  margin-top: 4px;
}

.mt-1\.5 {
  margin-top: 6px;
}

.mt-2 {
  margin-top: 8px;
}

.mt-2\.5 {
  margin-top: 10px;
}

.mt-3 {
  margin-top: 12px;
}

.mt-3\.5 {
  margin-top: 14px;
}

.mt-4 {
  margin-top: 16px;
}

.mt-5 {
  margin-top: 20px;
}

.mt-6 {
  margin-top: 24px;
}

.mt-7 {
  margin-top: 28px;
}

.mt-8 {
  margin-top: 32px;
}

.mt-9 {
  margin-top: 36px;
}

.mt-10 {
  margin-top: 40px;
}

.mt-11 {
  margin-top: 44px;
}

.mt-12 {
  margin-top: 48px;
}

.mt-14 {
  margin-top: 56px;
}

.mt-16 {
  margin-top: 64px;
}

.mt-20 {
  margin-top: 80px;
}

.mt-24 {
  margin-top: 96px;
}

.mt-28 {
  margin-top: 112px;
}

.mt-32 {
  margin-top: 128px;
}

.mt-36 {
  margin-top: 144px;
}

.mt-40 {
  margin-top: 160px;
}

.mt-44 {
  margin-top: 176px;
}

.mt-48 {
  margin-top: 192px;
}

.mt-52 {
  margin-top: 208px;
}

.mt-56 {
  margin-top: 224px;
}

.mt-60 {
  margin-top: 240px;
}

.mt-64 {
  margin-top: 256px;
}

.mt-72 {
  margin-top: 288px;
}

.mt-80 {
  margin-top: 320px;
}

.-mt-0\.5 {
  margin-top: -2px;
}

.-mt-1 {
  margin-top: -4px;
}

.-mt-1\.5 {
  margin-top: -6px;
}

.-mt-2 {
  margin-top: -8px;
}

.-mt-2\.5 {
  margin-top: -10px;
}

.-mt-3 {
  margin-top: -12px;
}

.-mt-3\.5 {
  margin-top: -14px;
}

.-mt-4 {
  margin-top: -16px;
}

.-mt-5 {
  margin-top: -20px;
}

.-mt-6 {
  margin-top: -24px;
}

.-mt-7 {
  margin-top: -28px;
}

.-mt-8 {
  margin-top: -32px;
}

.-mt-9 {
  margin-top: -36px;
}

.-mt-10 {
  margin-top: -40px;
}

.-mt-11 {
  margin-top: -44px;
}

.-mt-12 {
  margin-top: -48px;
}

.-mt-14 {
  margin-top: -56px;
}

.-mt-16 {
  margin-top: -64px;
}

.-mt-20 {
  margin-top: -80px;
}

.-mt-24 {
  margin-top: -96px;
}

.-mt-28 {
  margin-top: -112px;
}

.-mt-32 {
  margin-top: -128px;
}

.-mt-36 {
  margin-top: -144px;
}

.-mt-40 {
  margin-top: -160px;
}

.-mt-44 {
  margin-top: -176px;
}

.-mt-48 {
  margin-top: -192px;
}

.-mt-52 {
  margin-top: -208px;
}

.-mt-56 {
  margin-top: -224px;
}

.-mt-60 {
  margin-top: -240px;
}

.-mt-64 {
  margin-top: -256px;
}

.-mt-72 {
  margin-top: -288px;
}

.-mt-80 {
  margin-top: -320px;
}

/* Margin Bottom (mb) */

.mb-0\.5 {
  margin-bottom: 2px;
}

.mb-1 {
  margin-bottom: 4px;
}

.mb-1\.5 {
  margin-bottom: 6px;
}

.mb-2 {
  margin-bottom: 8px;
}

.mb-2\.5 {
  margin-bottom: 10px;
}

.mb-3 {
  margin-bottom: 12px;
}

.mb-3\.5 {
  margin-bottom: 14px;
}

.mb-4 {
  margin-bottom: 16px;
}

.mb-5 {
  margin-bottom: 20px;
}

.mb-6 {
  margin-bottom: 24px;
}

.mb-7 {
  margin-bottom: 28px;
}

.mb-8 {
  margin-bottom: 32px;
}

.mb-9 {
  margin-bottom: 36px;
}

.mb-10 {
  margin-bottom: 40px;
}

.mb-11 {
  margin-bottom: 44px;
}

.mb-12 {
  margin-bottom: 48px;
}

.mb-14 {
  margin-bottom: 56px;
}

.mb-16 {
  margin-bottom: 64px;
}

.mb-20 {
  margin-bottom: 80px;
}

.mb-24 {
  margin-bottom: 96px;
}

.mb-28 {
  margin-bottom: 112px;
}

.mb-32 {
  margin-bottom: 128px;
}

.mb-36 {
  margin-bottom: 144px;
}

.mb-40 {
  margin-bottom: 160px;
}

.mb-44 {
  margin-bottom: 176px;
}

.mb-48 {
  margin-bottom: 192px;
}

.mb-52 {
  margin-bottom: 208px;
}

.mb-56 {
  margin-bottom: 224px;
}

.mb-60 {
  margin-bottom: 240px;
}

.mb-64 {
  margin-bottom: 256px;
}

.mb-72 {
  margin-bottom: 288px;
}

.mb-80 {
  margin-bottom: 320px;
}

.-mb-0\.5 {
  margin-bottom: -2px;
}

.-mb-1 {
  margin-bottom: -4px;
}

.-mb-1\.5 {
  margin-bottom: -6px;
}

.-mb-2 {
  margin-bottom: -8px;
}

.-mb-2\.5 {
  margin-bottom: -10px;
}

.-mb-3 {
  margin-bottom: -12px;
}

.-mb-3\.5 {
  margin-bottom: -14px;
}

.-mb-4 {
  margin-bottom: -16px;
}

.-mb-5 {
  margin-bottom: -20px;
}

.-mb-6 {
  margin-bottom: -24px;
}

.-mb-7 {
  margin-bottom: -28px;
}

.-mb-8 {
  margin-bottom: -32px;
}

.-mb-9 {
  margin-bottom: -36px;
}

.-mb-10 {
  margin-bottom: -40px;
}

.-mb-11 {
  margin-bottom: -44px;
}

.-mb-12 {
  margin-bottom: -48px;
}

.-mb-14 {
  margin-bottom: -56px;
}

.-mb-16 {
  margin-bottom: -64px;
}

.-mb-20 {
  margin-bottom: -80px;
}

.-mb-24 {
  margin-bottom: -96px;
}

.-mb-28 {
  margin-bottom: -112px;
}

.-mb-32 {
  margin-bottom: -128px;
}

.-mb-36 {
  margin-bottom: -144px;
}

.-mb-40 {
  margin-bottom: -160px;
}

.-mb-44 {
  margin-bottom: -176px;
}

.-mb-48 {
  margin-bottom: -192px;
}

.-mb-52 {
  margin-bottom: -208px;
}

.-mb-56 {
  margin-bottom: -224px;
}

.-mb-60 {
  margin-bottom: -240px;
}

.-mb-64 {
  margin-bottom: -256px;
}

.-mb-72 {
  margin-bottom: -288px;
}

.-mb-80 {
  margin-bottom: -320px;
}

/* Margin X (mx) */

.mx-0\.5 {
  margin-left: 2px;
  margin-right: 2px;
}

.mx-1 {
  margin-left: 4px;
  margin-right: 4px;
}

.mx-1\.5 {
  margin-left: 6px;
  margin-right: 6px;
}

.mx-2 {
  margin-left: 8px;
  margin-right: 8px;
}

.mx-2\.5 {
  margin-left: 10px;
  margin-right: 10px;
}

.mx-3 {
  margin-left: 12px;
  margin-right: 12px;
}

.mx-3\.5 {
  margin-left: 14px;
  margin-right: 14px;
}

.mx-4 {
  margin-left: 16px;
  margin-right: 16px;
}

.mx-5 {
  margin-left: 20px;
  margin-right: 20px;
}

.mx-6 {
  margin-left: 24px;
  margin-right: 24px;
}

.mx-7 {
  margin-left: 28px;
  margin-right: 28px;
}

.mx-8 {
  margin-left: 32px;
  margin-right: 32px;
}

.mx-9 {
  margin-left: 36px;
  margin-right: 36px;
}

.mx-10 {
  margin-left: 40px;
  margin-right: 40px;
}

.mx-11 {
  margin-left: 44px;
  margin-right: 44px;
}

.mx-12 {
  margin-left: 48px;
  margin-right: 48px;
}

.mx-14 {
  margin-left: 56px;
  margin-right: 56px;
}

.mx-16 {
  margin-left: 64px;
  margin-right: 64px;
}

.mx-20 {
  margin-left: 80px;
  margin-right: 80px;
}

.mx-24 {
  margin-left: 96px;
  margin-right: 96px;
}

.mx-28 {
  margin-left: 112px;
  margin-right: 112px;
}

.mx-32 {
  margin-left: 128px;
  margin-right: 128px;
}

.mx-36 {
  margin-left: 144px;
  margin-right: 144px;
}

.mx-40 {
  margin-left: 160px;
  margin-right: 160px;
}

.mx-44 {
  margin-left: 176px;
  margin-right: 176px;
}

.mx-48 {
  margin-left: 192px;
  margin-right: 192px;
}

.mx-52 {
  margin-left: 208px;
  margin-right: 208px;
}

.mx-56 {
  margin-left: 224px;
  margin-right: 224px;
}

.mx-60 {
  margin-left: 240px;
  margin-right: 240px;
}

.mx-64 {
  margin-left: 256px;
  margin-right: 256px;
}

.mx-72 {
  margin-left: 288px;
  margin-right: 288px;
}

.mx-80 {
  margin-left: 320px;
  margin-right: 320px;
}

.-mx-0\.5 {
  margin-left: -2px;
  margin-right: -2px;
}

.-mx-1 {
  margin-left: -4px;
  margin-right: -4px;
}

.-mx-1\.5 {
  margin-left: -6px;
  margin-right: -6px;
}

.-mx-2 {
  margin-left: -8px;
  margin-right: -8px;
}

.-mx-2\.5 {
  margin-left: -10px;
  margin-right: -10px;
}

.-mx-3 {
  margin-left: -12px;
  margin-right: -12px;
}

.-mx-3\.5 {
  margin-left: -14px;
  margin-right: -14px;
}

.-mx-4 {
  margin-left: -16px;
  margin-right: -16px;
}

.-mx-5 {
  margin-left: -20px;
  margin-right: -20px;
}

.-mx-6 {
  margin-left: -24px;
  margin-right: -24px;
}

.-mx-7 {
  margin-left: -28px;
  margin-right: -28px;
}

.-mx-8 {
  margin-left: -32px;
  margin-right: -32px;
}

.-mx-9 {
  margin-left: -36px;
  margin-right: -36px;
}

.-mx-10 {
  margin-left: -40px;
  margin-right: -40px;
}

.-mx-11 {
  margin-left: -44px;
  margin-right: -44px;
}

.-mx-12 {
  margin-left: -48px;
  margin-right: -48px;
}

.-mx-14 {
  margin-left: -56px;
  margin-right: -56px;
}

.-mx-16 {
  margin-left: -64px;
  margin-right: -64px;
}

.-mx-20 {
  margin-left: -80px;
  margin-right: -80px;
}

.-mx-24 {
  margin-left: -96px;
  margin-right: -96px;
}

.-mx-28 {
  margin-left: -112px;
  margin-right: -112px;
}

.-mx-32 {
  margin-left: -128px;
  margin-right: -128px;
}

.-mx-36 {
  margin-left: -144px;
  margin-right: -144px;
}

.-mx-40 {
  margin-left: -160px;
  margin-right: -160px;
}

.-mx-44 {
  margin-left: -176px;
  margin-right: -176px;
}

.-mx-48 {
  margin-left: -192px;
  margin-right: -192px;
}

.-mx-52 {
  margin-left: -208px;
  margin-right: -208px;
}

.-mx-56 {
  margin-left: -224px;
  margin-right: -224px;
}

.-mx-60 {
  margin-left: -240px;
  margin-right: -240px;
}

.-mx-64 {
  margin-left: -256px;
  margin-right: -256px;
}

.-mx-72 {
  margin-left: -288px;
  margin-right: -288px;
}

.-mx-80 {
  margin-left: -320px;
  margin-right: -320px;
}

/* Margin Y (my) */

.my-0\.5 {
  margin-top: 2px;
  margin-bottom: 2px;
}

.my-1 {
  margin-top: 4px;
  margin-bottom: 4px;
}

.my-1\.5 {
  margin-top: 6px;
  margin-bottom: 6px;
}

.my-2 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.my-2\.5 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.my-3 {
  margin-top: 12px;
  margin-bottom: 12px;
}

.my-3\.5 {
  margin-top: 14px;
  margin-bottom: 14px;
}

.my-4 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.my-5 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.my-6 {
  margin-top: 24px;
  margin-bottom: 24px;
}

.my-7 {
  margin-top: 28px;
  margin-bottom: 28px;
}

.my-8 {
  margin-top: 32px;
  margin-bottom: 32px;
}

.my-9 {
  margin-top: 36px;
  margin-bottom: 36px;
}

.my-10 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.my-11 {
  margin-top: 44px;
  margin-bottom: 44px;
}

.my-12 {
  margin-top: 48px;
  margin-bottom: 48px;
}

.my-14 {
  margin-top: 56px;
  margin-bottom: 56px;
}

.my-16 {
  margin-top: 64px;
  margin-bottom: 64px;
}

.my-20 {
  margin-top: 80px;
  margin-bottom: 80px;
}

.my-24 {
  margin-top: 96px;
  margin-bottom: 96px;
}

.my-28 {
  margin-top: 112px;
  margin-bottom: 112px;
}

.my-32 {
  margin-top: 128px;
  margin-bottom: 128px;
}

.my-36 {
  margin-top: 144px;
  margin-bottom: 144px;
}

.my-40 {
  margin-top: 160px;
  margin-bottom: 160px;
}

.my-44 {
  margin-top: 176px;
  margin-bottom: 176px;
}

.my-48 {
  margin-top: 192px;
  margin-bottom: 192px;
}

.my-52 {
  margin-top: 208px;
  margin-bottom: 208px;
}

.my-56 {
  margin-top: 224px;
  margin-bottom: 224px;
}

.my-60 {
  margin-top: 240px;
  margin-bottom: 240px;
}

.my-64 {
  margin-top: 256px;
  margin-bottom: 256px;
}

.my-72 {
  margin-top: 288px;
  margin-bottom: 288px;
}

.my-80 {
  margin-top: 320px;
  margin-bottom: 320px;
}

.-my-0\.5 {
  margin-top: -2px;
  margin-bottom: -2px;
}

.-my-1 {
  margin-top: -4px;
  margin-bottom: -4px;
}

.-my-1\.5 {
  margin-top: -6px;
  margin-bottom: -6px;
}

.-my-2 {
  margin-top: -8px;
  margin-bottom: -8px;
}

.-my-2\.5 {
  margin-top: -10px;
  margin-bottom: -10px;
}

.-my-3 {
  margin-top: -12px;
  margin-bottom: -12px;
}

.-my-3\.5 {
  margin-top: -14px;
  margin-bottom: -14px;
}

.-my-4 {
  margin-top: -16px;
  margin-bottom: -16px;
}

.-my-5 {
  margin-top: -20px;
  margin-bottom: -20px;
}

.-my-6 {
  margin-top: -24px;
  margin-bottom: -24px;
}

.-my-7 {
  margin-top: -28px;
  margin-bottom: -28px;
}

.-my-8 {
  margin-top: -32px;
  margin-bottom: -32px;
}

.-my-9 {
  margin-top: -36px;
  margin-bottom: -36px;
}

.-my-10 {
  margin-top: -40px;
  margin-bottom: -40px;
}

.-my-11 {
  margin-top: -44px;
  margin-bottom: -44px;
}

.-my-12 {
  margin-top: -48px;
  margin-bottom: -48px;
}

.-my-14 {
  margin-top: -56px;
  margin-bottom: -56px;
}

.-my-16 {
  margin-top: -64px;
  margin-bottom: -64px;
}

.-my-20 {
  margin-top: -80px;
  margin-bottom: -80px;
}

.-my-24 {
  margin-top: -96px;
  margin-bottom: -96px;
}

.-my-28 {
  margin-top: -112px;
  margin-bottom: -112px;
}

.-my-32 {
  margin-top: -128px;
  margin-bottom: -128px;
}

.-my-36 {
  margin-top: -144px;
  margin-bottom: -144px;
}

.-my-40 {
  margin-top: -160px;
  margin-bottom: -160px;
}

.-my-44 {
  margin-top: -176px;
  margin-bottom: -176px;
}

.-my-48 {
  margin-top: -192px;
  margin-bottom: -192px;
}

.-my-52 {
  margin-top: -208px;
  margin-bottom: -208px;
}

.-my-56 {
  margin-top: -224px;
  margin-bottom: -224px;
}

.-my-60 {
  margin-top: -240px;
  margin-bottom: -240px;
}

.-my-64 {
  margin-top: -256px;
  margin-bottom: -256px;
}

.-my-72 {
  margin-top: -288px;
  margin-bottom: -288px;
}

.-my-80 {
  margin-top: -320px;
  margin-bottom: -320px;
}

/* Margin inline start (ms) */

/* Margin Inline Start (ms) */
.ms-0\.5 {
  margin-inline-start: 2px;
}

.ms-1 {
  margin-inline-start: 4px;
}

.ms-1\.5 {
  margin-inline-start: 6px;
}

.ms-2 {
  margin-inline-start: 8px;
}

.ms-2\.5 {
  margin-inline-start: 10px;
}

.ms-3 {
  margin-inline-start: 12px;
}

.ms-3\.5 {
  margin-inline-start: 14px;
}

.ms-4 {
  margin-inline-start: 16px;
}

.ms-5 {
  margin-inline-start: 20px;
}

.ms-6 {
  margin-inline-start: 24px;
}

.ms-7 {
  margin-inline-start: 28px;
}

.ms-8 {
  margin-inline-start: 32px;
}

.ms-9 {
  margin-inline-start: 36px;
}

.ms-10 {
  margin-inline-start: 40px;
}

.ms-11 {
  margin-inline-start: 44px;
}

.ms-12 {
  margin-inline-start: 48px;
}

.ms-14 {
  margin-inline-start: 56px;
}

.ms-16 {
  margin-inline-start: 64px;
}

.ms-20 {
  margin-inline-start: 80px;
}

.ms-24 {
  margin-inline-start: 96px;
}

.ms-28 {
  margin-inline-start: 112px;
}

.ms-32 {
  margin-inline-start: 128px;
}

.ms-36 {
  margin-inline-start: 144px;
}

.ms-40 {
  margin-inline-start: 160px;
}

.ms-44 {
  margin-inline-start: 176px;
}

.ms-48 {
  margin-inline-start: 192px;
}

.ms-52 {
  margin-inline-start: 208px;
}

.ms-56 {
  margin-inline-start: 224px;
}

.ms-60 {
  margin-inline-start: 240px;
}

.ms-64 {
  margin-inline-start: 256px;
}

.ms-72 {
  margin-inline-start: 288px;
}

.ms-80 {
  margin-inline-start: 320px;
}

.-ms-0\.5 {
  margin-inline-start: -2px;
}

.-ms-1 {
  margin-inline-start: -4px;
}

.-ms-1\.5 {
  margin-inline-start: -6px;
}

.-ms-2 {
  margin-inline-start: -8px;
}

.-ms-2\.5 {
  margin-inline-start: -10px;
}

.-ms-3 {
  margin-inline-start: -12px;
}

.-ms-3\.5 {
  margin-inline-start: -14px;
}

.-ms-4 {
  margin-inline-start: -16px;
}

.-ms-5 {
  margin-inline-start: -20px;
}

.-ms-6 {
  margin-inline-start: -24px;
}

.-ms-7 {
  margin-inline-start: -28px;
}

.-ms-8 {
  margin-inline-start: -32px;
}

.-ms-9 {
  margin-inline-start: -36px;
}

.-ms-10 {
  margin-inline-start: -40px;
}

.-ms-11 {
  margin-inline-start: -44px;
}

.-ms-12 {
  margin-inline-start: -48px;
}

.-ms-14 {
  margin-inline-start: -56px;
}

.-ms-16 {
  margin-inline-start: -64px;
}

.-ms-20 {
  margin-inline-start: -80px;
}

.-ms-24 {
  margin-inline-start: -96px;
}

.-ms-28 {
  margin-inline-start: -112px;
}

.-ms-32 {
  margin-inline-start: -128px;
}

.-ms-36 {
  margin-inline-start: -144px;
}

.-ms-40 {
  margin-inline-start: -160px;
}

.-ms-44 {
  margin-inline-start: -176px;
}

.-ms-48 {
  margin-inline-start: -192px;
}

.-ms-52 {
  margin-inline-start: -208px;
}

.-ms-56 {
  margin-inline-start: -224px;
}

.-ms-60 {
  margin-inline-start: -240px;
}

.-ms-64 {
  margin-inline-start: -256px;
}

.-ms-72 {
  margin-inline-start: -288px;
}

.-ms-80 {
  margin-inline-start: -320px;
}

/* Margin inline end (me) */

.me-0\.5 {
  margin-inline-end: 2px;
}

.me-1 {
  margin-inline-end: 4px;
}

.me-1\.5 {
  margin-inline-end: 6px;
}

.me-2 {
  margin-inline-end: 8px;
}

.me-2\.5 {
  margin-inline-end: 10px;
}

.me-3 {
  margin-inline-end: 12px;
}

.me-3\.5 {
  margin-inline-end: 14px;
}

.me-4 {
  margin-inline-end: 16px;
}

.me-5 {
  margin-inline-end: 20px;
}

.me-6 {
  margin-inline-end: 24px;
}

.me-7 {
  margin-inline-end: 28px;
}

.me-8 {
  margin-inline-end: 32px;
}

.me-9 {
  margin-inline-end: 36px;
}

.me-10 {
  margin-inline-end: 40px;
}

.me-11 {
  margin-inline-end: 44px;
}

.me-12 {
  margin-inline-end: 48px;
}

.me-14 {
  margin-inline-end: 56px;
}

.me-16 {
  margin-inline-end: 64px;
}

.me-20 {
  margin-inline-end: 80px;
}

.me-24 {
  margin-inline-end: 96px;
}

.me-28 {
  margin-inline-end: 112px;
}

.me-32 {
  margin-inline-end: 128px;
}

.me-36 {
  margin-inline-end: 144px;
}

.me-40 {
  margin-inline-end: 160px;
}

.me-44 {
  margin-inline-end: 176px;
}

.me-48 {
  margin-inline-end: 192px;
}

.me-52 {
  margin-inline-end: 208px;
}

.me-56 {
  margin-inline-end: 224px;
}

.me-60 {
  margin-inline-end: 240px;
}

.me-64 {
  margin-inline-end: 256px;
}

.me-72 {
  margin-inline-end: 288px;
}

.me-80 {
  margin-inline-end: 320px;
}

.-me-0\.5 {
  margin-inline-end: -2px;
}

.-me-1 {
  margin-inline-end: -4px;
}

.-me-1\.5 {
  margin-inline-end: -6px;
}

.-me-2 {
  margin-inline-end: -8px;
}

.-me-2\.5 {
  margin-inline-end: -10px;
}

.-me-3 {
  margin-inline-end: -12px;
}

.-me-3\.5 {
  margin-inline-end: -14px;
}

.-me-4 {
  margin-inline-end: -16px;
}

.-me-5 {
  margin-inline-end: -20px;
}

.-me-6 {
  margin-inline-end: -24px;
}

.-me-7 {
  margin-inline-end: -28px;
}

.-me-8 {
  margin-inline-end: -32px;
}

.-me-9 {
  margin-inline-end: -36px;
}

.-me-10 {
  margin-inline-end: -40px;
}

.-me-11 {
  margin-inline-end: -44px;
}

.-me-12 {
  margin-inline-end: -48px;
}

.-me-14 {
  margin-inline-end: -56px;
}

.-me-16 {
  margin-inline-end: -64px;
}

.-me-20 {
  margin-inline-end: -80px;
}

.-me-24 {
  margin-inline-end: -96px;
}

.-me-28 {
  margin-inline-end: -112px;
}

.-me-32 {
  margin-inline-end: -128px;
}

.-me-36 {
  margin-inline-end: -144px;
}

.-me-40 {
  margin-inline-end: -160px;
}

.-me-44 {
  margin-inline-end: -176px;
}

.-me-48 {
  margin-inline-end: -192px;
}

.-me-52 {
  margin-inline-end: -208px;
}

.-me-56 {
  margin-inline-end: -224px;
}

.-me-60 {
  margin-inline-end: -240px;
}

.-me-64 {
  margin-inline-end: -256px;
}

.-me-72 {
  margin-inline-end: -288px;
}

.-me-80 {
  margin-inline-end: -320px;
}

/* Padding */

/* Padding (p) */
.p-0\.5 {
  padding: 2px;
}

.p-1 {
  padding: 4px;
}

.p-1\.5 {
  padding: 6px;
}

.p-2 {
  padding: 8px;
}

.p-2\.5 {
  padding: 10px;
}

.p-3 {
  padding: 12px;
}

.p-3\.5 {
  padding: 14px;
}

.p-4 {
  padding: 16px;
}

.p-5 {
  padding: 20px;
}

.p-6 {
  padding: 24px;
}

.p-7 {
  padding: 28px;
}

.p-8 {
  padding: 32px;
}

.p-9 {
  padding: 36px;
}

.p-10 {
  padding: 40px;
}

.p-11 {
  padding: 44px;
}

.p-12 {
  padding: 48px;
}

.p-14 {
  padding: 56px;
}

.p-16 {
  padding: 64px;
}

.p-20 {
  padding: 80px;
}

.p-24 {
  padding: 96px;
}

.p-28 {
  padding: 112px;
}

.p-32 {
  padding: 128px;
}

.p-36 {
  padding: 144px;
}

.p-40 {
  padding: 160px;
}

.p-44 {
  padding: 176px;
}

.p-48 {
  padding: 192px;
}

.p-52 {
  padding: 208px;
}

.p-56 {
  padding: 224px;
}

.p-60 {
  padding: 240px;
}

.p-64 {
  padding: 256px;
}

.p-72 {
  padding: 288px;
}

.p-80 {
  padding: 320px;
}

/* Padding-left */

.pl-0\.5 {
  padding-left: 2px;
}

.pl-1 {
  padding-left: 4px;
}

.pl-1\.5 {
  padding-left: 6px;
}

.pl-2 {
  padding-left: 8px;
}

.pl-2\.5 {
  padding-left: 10px;
}

.pl-3 {
  padding-left: 12px;
}

.pl-3\.5 {
  padding-left: 14px;
}

.pl-4 {
  padding-left: 16px;
}

.pl-5 {
  padding-left: 20px;
}

.pl-6 {
  padding-left: 24px;
}

.pl-7 {
  padding-left: 28px;
}

.pl-8 {
  padding-left: 32px;
}

.pl-9 {
  padding-left: 36px;
}

.pl-10 {
  padding-left: 40px;
}

.pl-11 {
  padding-left: 44px;
}

.pl-12 {
  padding-left: 48px;
}

.pl-14 {
  padding-left: 56px;
}

.pl-16 {
  padding-left: 64px;
}

.pl-20 {
  padding-left: 80px;
}

.pl-24 {
  padding-left: 96px;
}

.pl-28 {
  padding-left: 112px;
}

.pl-32 {
  padding-left: 128px;
}

.pl-36 {
  padding-left: 144px;
}

.pl-40 {
  padding-left: 160px;
}

.pl-44 {
  padding-left: 176px;
}

.pl-48 {
  padding-left: 192px;
}

.pl-52 {
  padding-left: 208px;
}

.pl-56 {
  padding-left: 224px;
}

.pl-60 {
  padding-left: 240px;
}

.pl-64 {
  padding-left: 256px;
}

.pl-72 {
  padding-left: 288px;
}

.pl-80 {
  padding-left: 320px;
}

/* Padding-right */

.pr-0\.5 {
  padding-right: 2px;
}

.pr-1 {
  padding-right: 4px;
}

.pr-1\.5 {
  padding-right: 6px;
}

.pr-2 {
  padding-right: 8px;
}

.pr-2\.5 {
  padding-right: 10px;
}

.pr-3 {
  padding-right: 12px;
}

.pr-3\.5 {
  padding-right: 14px;
}

.pr-4 {
  padding-right: 16px;
}

.pr-5 {
  padding-right: 20px;
}

.pr-6 {
  padding-right: 24px;
}

.pr-7 {
  padding-right: 28px;
}

.pr-8 {
  padding-right: 32px;
}

.pr-9 {
  padding-right: 36px;
}

.pr-10 {
  padding-right: 40px;
}

.pr-11 {
  padding-right: 44px;
}

.pr-12 {
  padding-right: 48px;
}

.pr-14 {
  padding-right: 56px;
}

.pr-16 {
  padding-right: 64px;
}

.pr-20 {
  padding-right: 80px;
}

.pr-24 {
  padding-right: 96px;
}

.pr-28 {
  padding-right: 112px;
}

.pr-32 {
  padding-right: 128px;
}

.pr-36 {
  padding-right: 144px;
}

.pr-40 {
  padding-right: 160px;
}

.pr-44 {
  padding-right: 176px;
}

.pr-48 {
  padding-right: 192px;
}

.pr-52 {
  padding-right: 208px;
}

.pr-56 {
  padding-right: 224px;
}

.pr-60 {
  padding-right: 240px;
}

.pr-64 {
  padding-right: 256px;
}

.pr-72 {
  padding-right: 288px;
}

.pr-80 {
  padding-right: 320px;
}

/* Padding-top */

.pt-0\.5 {
  padding-top: 2px;
}

.pt-1 {
  padding-top: 4px;
}

.pt-1\.5 {
  padding-top: 6px;
}

.pt-2 {
  padding-top: 8px;
}

.pt-2\.5 {
  padding-top: 10px;
}

.pt-3 {
  padding-top: 12px;
}

.pt-3\.5 {
  padding-top: 14px;
}

.pt-4 {
  padding-top: 16px;
}

.pt-5 {
  padding-top: 20px;
}

.pt-6 {
  padding-top: 24px;
}

.pt-7 {
  padding-top: 28px;
}

.pt-8 {
  padding-top: 32px;
}

.pt-9 {
  padding-top: 36px;
}

.pt-10 {
  padding-top: 40px;
}

.pt-11 {
  padding-top: 44px;
}

.pt-12 {
  padding-top: 48px;
}

.pt-14 {
  padding-top: 56px;
}

.pt-16 {
  padding-top: 64px;
}

.pt-20 {
  padding-top: 80px;
}

.pt-24 {
  padding-top: 96px;
}

.pt-28 {
  padding-top: 112px;
}

.pt-32 {
  padding-top: 128px;
}

.pt-36 {
  padding-top: 144px;
}

.pt-40 {
  padding-top: 160px;
}

.pt-44 {
  padding-top: 176px;
}

.pt-48 {
  padding-top: 192px;
}

.pt-52 {
  padding-top: 208px;
}

.pt-56 {
  padding-top: 224px;
}

.pt-60 {
  padding-top: 240px;
}

.pt-64 {
  padding-top: 256px;
}

.pt-72 {
  padding-top: 288px;
}

.pt-80 {
  padding-top: 320px;
}

/* Padding-bottom */

.pb-0\.5 {
  padding-bottom: 2px;
}

.pb-1 {
  padding-bottom: 4px;
}

.pb-1\.5 {
  padding-bottom: 6px;
}

.pb-2 {
  padding-bottom: 8px;
}

.pb-2\.5 {
  padding-bottom: 10px;
}

.pb-3 {
  padding-bottom: 12px;
}

.pb-3\.5 {
  padding-bottom: 14px;
}

.pb-4 {
  padding-bottom: 16px;
}

.pb-5 {
  padding-bottom: 20px;
}

.pb-6 {
  padding-bottom: 24px;
}

.pb-7 {
  padding-bottom: 28px;
}

.pb-8 {
  padding-bottom: 32px;
}

.pb-9 {
  padding-bottom: 36px;
}

.pb-10 {
  padding-bottom: 40px;
}

.pb-11 {
  padding-bottom: 44px;
}

.pb-12 {
  padding-bottom: 48px;
}

.pb-14 {
  padding-bottom: 56px;
}

.pb-16 {
  padding-bottom: 64px;
}

.pb-20 {
  padding-bottom: 80px;
}

.pb-24 {
  padding-bottom: 96px;
}

.pb-28 {
  padding-bottom: 112px;
}

.pb-32 {
  padding-bottom: 128px;
}

.pb-36 {
  padding-bottom: 144px;
}

.pb-40 {
  padding-bottom: 160px;
}

.pb-44 {
  padding-bottom: 176px;
}

.pb-48 {
  padding-bottom: 192px;
}

.pb-52 {
  padding-bottom: 208px;
}

.pb-56 {
  padding-bottom: 224px;
}

.pb-60 {
  padding-bottom: 240px;
}

.pb-64 {
  padding-bottom: 256px;
}

.pb-72 {
  padding-bottom: 288px;
}

.pb-80 {
  padding-bottom: 320px;
}

/* Padding-x */

.px-0\.5 {
  padding-left: 2px;
  padding-right: 2px;
}

.px-1 {
  padding-left: 4px;
  padding-right: 4px;
}

.px-1\.5 {
  padding-left: 6px;
  padding-right: 6px;
}

.px-2 {
  padding-left: 8px;
  padding-right: 8px;
}

.px-2\.5 {
  padding-left: 10px;
  padding-right: 10px;
}

.px-3 {
  padding-left: 12px;
  padding-right: 12px;
}

.px-3\.5 {
  padding-left: 14px;
  padding-right: 14px;
}

.px-4 {
  padding-left: 16px;
  padding-right: 16px;
}

.px-5 {
  padding-left: 20px;
  padding-right: 20px;
}

.px-6 {
  padding-left: 24px;
  padding-right: 24px;
}

.px-7 {
  padding-left: 28px;
  padding-right: 28px;
}

.px-8 {
  padding-left: 32px;
  padding-right: 32px;
}

.px-9 {
  padding-left: 36px;
  padding-right: 36px;
}

.px-10 {
  padding-left: 40px;
  padding-right: 40px;
}

.px-11 {
  padding-left: 44px;
  padding-right: 44px;
}

.px-12 {
  padding-left: 48px;
  padding-right: 48px;
}

.px-14 {
  padding-left: 56px;
  padding-right: 56px;
}

.px-16 {
  padding-left: 64px;
  padding-right: 64px;
}

.px-20 {
  padding-left: 80px;
  padding-right: 80px;
}

.px-24 {
  padding-left: 96px;
  padding-right: 96px;
}

.px-28 {
  padding-left: 112px;
  padding-right: 112px;
}

.px-32 {
  padding-left: 128px;
  padding-right: 128px;
}

.px-36 {
  padding-left: 144px;
  padding-right: 144px;
}

.px-40 {
  padding-left: 160px;
  padding-right: 160px;
}

.px-44 {
  padding-left: 176px;
  padding-right: 176px;
}

.px-48 {
  padding-left: 192px;
  padding-right: 192px;
}

.px-52 {
  padding-left: 208px;
  padding-right: 208px;
}

.px-56 {
  padding-left: 224px;
  padding-right: 224px;
}

.px-60 {
  padding-left: 240px;
  padding-right: 240px;
}

.px-64 {
  padding-left: 256px;
  padding-right: 256px;
}

.px-72 {
  padding-left: 288px;
  padding-right: 288px;
}

.px-80 {
  padding-left: 320px;
  padding-right: 320px;
}

/* Padding-y */

.py-0\.5 {
  padding-top: 2px;
  padding-bottom: 2px;
}

.py-1 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.py-1\.5 {
  padding-top: 6px;
  padding-bottom: 6px;
}

.py-2 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.py-2\.5 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-3 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.py-3\.5 {
  padding-top: 14px;
  padding-bottom: 14px;
}

.py-4 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.py-5 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.py-6 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.py-7 {
  padding-top: 28px;
  padding-bottom: 28px;
}

.py-8 {
  padding-top: 32px;
  padding-bottom: 32px;
}

.py-9 {
  padding-top: 36px;
  padding-bottom: 36px;
}

.py-10 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.py-11 {
  padding-top: 44px;
  padding-bottom: 44px;
}

.py-12 {
  padding-top: 48px;
  padding-bottom: 48px;
}

.py-14 {
  padding-top: 56px;
  padding-bottom: 56px;
}

.py-16 {
  padding-top: 64px;
  padding-bottom: 64px;
}

.py-20 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.py-24 {
  padding-top: 96px;
  padding-bottom: 96px;
}

.py-28 {
  padding-top: 112px;
  padding-bottom: 112px;
}

.py-32 {
  padding-top: 128px;
  padding-bottom: 128px;
}

.py-36 {
  padding-top: 144px;
  padding-bottom: 144px;
}

.py-40 {
  padding-top: 160px;
  padding-bottom: 160px;
}

.py-44 {
  padding-top: 176px;
  padding-bottom: 176px;
}

.py-48 {
  padding-top: 192px;
  padding-bottom: 192px;
}

.py-52 {
  padding-top: 208px;
  padding-bottom: 208px;
}

.py-56 {
  padding-top: 224px;
  padding-bottom: 224px;
}

.py-60 {
  padding-top: 240px;
  padding-bottom: 240px;
}

.py-64 {
  padding-top: 256px;
  padding-bottom: 256px;
}

.py-72 {
  padding-top: 288px;
  padding-bottom: 288px;
}

.py-80 {
  padding-top: 320px;
  padding-bottom: 320px;
}

/* Padding inline-start */

.ps-0\.5 {
  padding-inline-start: 2px;
}

.ps-1 {
  padding-inline-start: 4px;
}

.ps-1\.5 {
  padding-inline-start: 6px;
}

.ps-2 {
  padding-inline-start: 8px;
}

.ps-2\.5 {
  padding-inline-start: 10px;
}

.ps-3 {
  padding-inline-start: 12px;
}

.ps-3\.5 {
  padding-inline-start: 14px;
}

.ps-4 {
  padding-inline-start: 16px;
}

.ps-5 {
  padding-inline-start: 20px;
}

.ps-6 {
  padding-inline-start: 24px;
}

.ps-7 {
  padding-inline-start: 28px;
}

.ps-8 {
  padding-inline-start: 32px;
}

.ps-9 {
  padding-inline-start: 36px;
}

.ps-10 {
  padding-inline-start: 40px;
}

.ps-11 {
  padding-inline-start: 44px;
}

.ps-12 {
  padding-inline-start: 48px;
}

.ps-14 {
  padding-inline-start: 56px;
}

.ps-16 {
  padding-inline-start: 64px;
}

.ps-20 {
  padding-inline-start: 80px;
}

.ps-24 {
  padding-inline-start: 96px;
}

.ps-28 {
  padding-inline-start: 112px;
}

.ps-32 {
  padding-inline-start: 128px;
}

.ps-36 {
  padding-inline-start: 144px;
}

.ps-40 {
  padding-inline-start: 160px;
}

.ps-44 {
  padding-inline-start: 176px;
}

.ps-48 {
  padding-inline-start: 192px;
}

.ps-52 {
  padding-inline-start: 208px;
}

.ps-56 {
  padding-inline-start: 224px;
}

.ps-60 {
  padding-inline-start: 240px;
}

.ps-64 {
  padding-inline-start: 256px;
}

.ps-72 {
  padding-inline-start: 288px;
}

.ps-80 {
  padding-inline-start: 320px;
}

/* Padding inline-end */

.pe-0\.5 {
  padding-inline-end: 2px;
}

.pe-1 {
  padding-inline-end: 4px;
}

.pe-1\.5 {
  padding-inline-end: 6px;
}

.pe-2 {
  padding-inline-end: 8px;
}

.pe-2\.5 {
  padding-inline-end: 10px;
}

.pe-3 {
  padding-inline-end: 12px;
}

.pe-3\.5 {
  padding-inline-end: 14px;
}

.pe-4 {
  padding-inline-end: 16px;
}

.pe-5 {
  padding-inline-end: 20px;
}

.pe-6 {
  padding-inline-end: 24px;
}

.pe-7 {
  padding-inline-end: 28px;
}

.pe-8 {
  padding-inline-end: 32px;
}

.pe-9 {
  padding-inline-end: 36px;
}

.pe-10 {
  padding-inline-end: 40px;
}

.pe-11 {
  padding-inline-end: 44px;
}

.pe-12 {
  padding-inline-end: 48px;
}

.pe-14 {
  padding-inline-end: 56px;
}

.pe-16 {
  padding-inline-end: 64px;
}

.pe-20 {
  padding-inline-end: 80px;
}

.pe-24 {
  padding-inline-end: 96px;
}

.pe-28 {
  padding-inline-end: 112px;
}

.pe-32 {
  padding-inline-end: 128px;
}

.pe-36 {
  padding-inline-end: 144px;
}

.pe-40 {
  padding-inline-end: 160px;
}

.pe-44 {
  padding-inline-end: 176px;
}

.pe-48 {
  padding-inline-end: 192px;
}

.pe-52 {
  padding-inline-end: 208px;
}

.pe-56 {
  padding-inline-end: 224px;
}

.pe-60 {
  padding-inline-end: 240px;
}

.pe-64 {
  padding-inline-end: 256px;
}

.pe-72 {
  padding-inline-end: 288px;
}

.pe-80 {
  padding-inline-end: 320px;
}
